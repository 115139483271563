import { queryOptions, type QueryKey } from "@tanstack/react-query";
import type { AdminMetalSpecCreateBody, AdminMetalSpecUpdateBody, MetalSpec } from "@warrenio/api-spec/spec.oats.gen";
import { mapFromEntries, mergeMaps } from "@warrenio/utils/collections/maps";
import { atomFamily } from "jotai/utils";
import { getResponseData, type ApiClient } from "../../../modules/api/apiClient.ts";
import { atomAllLocationsQuery } from "../../../modules/location/atomAllLocationsQuery.ts";
import { jsonEncodedBody } from "../../../utils/fetchClient.ts";
import { adminMutation } from "../../../utils/query/adminMutation.ts";
import { atomFromStandardQueryOptions } from "../../../utils/query/queryAtom.ts";

//#region Response
export interface MetalSpecLoc extends MetalSpec {
    $type: "metal_spec";
    location: string;
}

export type Response = Map<MetalSpecLoc["uuid"], MetalSpecLoc>;

function specFromData(data: MetalSpec, location: string): MetalSpecLoc {
    return { ...data, $type: "metal_spec", location };
}
//#endregion

//#region Query and parameters
export interface Params {
    location: string;
}

export function getQueryKey(params?: Params): QueryKey {
    return params == null ? ["metal_admin/spec"] : ["metal_admin/spec", params.location];
}

export function getQuery(client: ApiClient, params: Params) {
    return queryOptions({
        queryKey: getQueryKey(params),
        queryFn: async ({ signal }): Promise<Response> => {
            const { location } = params;
            return mapFromEntries(
                getResponseData(
                    await client.GET("/{location}/admin/metal/specs", {
                        signal,
                        params: { path: { location } },
                    }),
                ),
                (spec) => [spec.uuid, specFromData(spec, location)] as const,
            );
        },
    });
}
//#endregion

//#region Atoms
export const specLocationQueryAtom = atomFamily((location: string) =>
    atomFromStandardQueryOptions(getQuery, { location }),
);

export const metalSpecQueryAtom = atomAllLocationsQuery(specLocationQueryAtom, (results) => mergeMaps(results));
//#endregion

//#region Mutations
export const specCreateMutation = adminMutation((apiClient) => ({
    entity: "spec",
    operation: "create",
    requestFn: async ({ body, location }: { body: AdminMetalSpecCreateBody; location: string }) =>
        await apiClient.POST("/{location}/admin/metal/specs", {
            ...jsonEncodedBody,
            body,
            params: { path: { location } },
        }),
    invalidateQueryKey: getQueryKey,
}));

export const specUpdateMutation = adminMutation((apiClient) => ({
    entity: "spec",
    operation: "update",
    requestFn: async ({ body, location, uuid }: { body: AdminMetalSpecUpdateBody; location: string; uuid: string }) =>
        await apiClient.PATCH("/{location}/admin/metal/specs/{uuid}", {
            ...jsonEncodedBody,
            body,
            params: { path: { location, uuid } },
        }),
    invalidateQueryKey: getQueryKey,
}));

export const specDeleteMutation = adminMutation((apiClient) => ({
    entity: "spec",
    operation: "delete",
    requestFn: async ({ location, uuid }: { location: string; uuid: string }) =>
        await apiClient.DELETE("/{location}/admin/metal/specs/{uuid}", {
            params: { path: { location, uuid } },
        }),
    invalidateQueryKey: getQueryKey,
}));
//#endregion
