import type { AdminMetalSpecCreateBody } from "@warrenio/api-spec/spec.oats.gen";
import { useForm } from "react-hook-form";
import { keys, pick } from "remeda";
import { useStandardMutation } from "../../../modules/api/useStandardMutation.ts";
import type { LocationInputs } from "../../../modules/location/LocationField.tsx";
import { useLocationsForType } from "../../../modules/location/query.ts";
import { changedFields } from "../../../utils/changedFields.ts";
import { AdminLocationField } from "../../AdminLocationField.tsx";
import { AFCheckbox, AFTextField } from "../../form/AdminFormFields.tsx";
import { WAdminModalCrudForm } from "../../form/AdminModalContent.tsx";
import { ATextArea } from "../../form/Fields.tsx";
import { UuidField } from "../../form/UuidField.tsx";
import { specFields } from "./specFields.tsx";
import { type MetalSpecLoc, specCreateMutation, specUpdateMutation } from "./specQuery.ts";

interface Inputs extends AdminMetalSpecCreateBody, LocationInputs {}

export function SpecItemModal({ item }: { item?: MetalSpecLoc | undefined }) {
    //#region Hooks
    const locations = useLocationsForType("bare_metal");

    const createDefaults: Inputs = {
        location: locations.defaultLocation,

        title: "",
        subtitle: "",
        description: "",
        is_visible: true,
    };

    const form = useForm<Inputs>({
        defaultValues: item ? pick(item, keys(createDefaults)) : createDefaults,
    });
    const { control } = form;

    const createMutation = useStandardMutation(specCreateMutation);
    const updateMutation = useStandardMutation(specUpdateMutation);

    //#endregion

    async function onSubmit({ location, ...body }: Inputs) {
        if (item) {
            const changed = changedFields(item, body);
            if (changed) {
                await updateMutation.mutateAsync({
                    body: changed,
                    location,
                    uuid: item.uuid,
                });
            }
        } else {
            await createMutation.mutateAsync({ body, location });
        }
    }

    const isUpdate = !!item;

    return (
        <WAdminModalCrudForm entityType="spec" isUpdate={isUpdate} form={form} onSubmit={onSubmit}>
            <UuidField value={item?.uuid} />

            <AdminLocationField locations={locations} isUpdate={isUpdate} />

            <AFTextField control={control} name="title" isRequired config={specFields.title} />
            <AFTextField control={control} name="subtitle" config={specFields.subtitle} />
            <AFTextField control={control} name="description" isRequired config={specFields.description}>
                <ATextArea />
            </AFTextField>

            <AFCheckbox control={control} name="is_visible" config={specFields.is_visible} />
        </WAdminModalCrudForm>
    );
}
