import { Controller, useFormContext } from "react-hook-form";
import { FormField } from "../components/forms/FormField.tsx";
import { controllerProps, formPropsToFormFieldProps } from "../components/forms/ariaController.tsx";
import { LocationText } from "../modules/location/LocationDetail.tsx";
import type { LocationInputs } from "../modules/location/LocationField.tsx";
import { LocationSelectCustom } from "../modules/location/LocationSelect.tsx";
import type { LocationsForType } from "../modules/location/location.ts";

export function AdminLocationField({ locations, isUpdate }: { locations: LocationsForType; isUpdate: boolean }) {
    const { control, watch } = useFormContext<LocationInputs>();

    return (
        <Controller
            control={control}
            name="location"
            {...controllerProps({ isRequired: true })}
            render={(p) => (
                <FormField isRequired wide label="Location" {...formPropsToFormFieldProps(p)}>
                    {isUpdate ? (
                        // Location is read-only if it is an update
                        <LocationText slug={watch("location")} />
                    ) : (
                        <LocationSelectCustom
                            items={locations.locations}
                            valueKey={p.field.value}
                            onChange={(_, key) => p.field.onChange(key)}
                            isReadOnly={isUpdate}
                        />
                    )}
                </FormField>
            )}
        />
    );
}
