import { apiDatetimeToDate } from "@warrenio/api-spec/conversion";
import { field, type FieldsOf } from "../../AdminTable.tsx";
import type { MetalSpecLoc } from "./specQuery.ts";

export const specFields = {
    uuid: field("UUID", (e) => e.uuid, { copyable: true, inTable: false }),
    title: field("Title", (e) => e.title),
    subtitle: field("Subtitle", (e) => e.subtitle),
    description: field("Description", (e) => e.description),
    created_at: field("Created At", (e) => apiDatetimeToDate(e.created_at)),
    updated_at: field("Updated At", (e) => apiDatetimeToDate(e.updated_at), { inTable: false }),
    is_visible: field("Is visible", (e) => e.is_visible),
    // is_default: field("Is default", (e) => false),
} satisfies FieldsOf<MetalSpecLoc>;
